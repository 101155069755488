// const ACCESS_TOKEN = 'DEqmF4Yo4MzrH2EPLJRu01Om8QsRui'
// const BUSINESS_ID = "QnVzaW5lc3M6MjlkZWJlODYtZmNhNS00NTcyLWI2ZTItYjljYzkxNWY1ZmJj"
// const ANCHOR_ACCOUNT_ID = "QWNjb3VudDoyMDgyMzE0MDA4NjA5Nzg5Mjc0O0J1c2luZXNzOjI5ZGViZTg2LWZjYTUtNDU3Mi1iNmUyLWI5Y2M5MTVmNWZiYw=="
// const CAPITAL_ONE_ID = "QWNjb3VudDoyMDgzNzEzMDMyOTI3Njc5NzExO0J1c2luZXNzOjI5ZGViZTg2LWZjYTUtNDU3Mi1iNmUyLWI5Y2M5MTVmNWZiYw=="
// const SHIPPING_ACCOUNT_ID = "QWNjb3VudDoyMDgwOTAzNzQ1MTM0MjM3NTcxO0J1c2luZXNzOjI5ZGViZTg2LWZjYTUtNDU3Mi1iNmUyLWI5Y2M5MTVmNWZiYw=="
// const SALES_ACCOUNT_ID = "QWNjb3VudDoyMDgwODc3Njk2MTY1OTc1NDUyO0J1c2luZXNzOjI5ZGViZTg2LWZjYTUtNDU3Mi1iNmUyLWI5Y2M5MTVmNWZiYw=="
// const EBAY_FEES_ACCOUNT_ID = "QWNjb3VudDoyMDgxNDM4NDk0MDM4NzkxMjcyO0J1c2luZXNzOjI5ZGViZTg2LWZjYTUtNDU3Mi1iNmUyLWI5Y2M5MTVmNWZiYw=="

module.exports = class eBay {
  config = {}
  constructor(config) {
    this.config = config || {}
    this.getInput = this.getInput.bind(this)
    this.mapInputs = this.mapInputs.bind(this)
    this.transactionsToInputs = this.transactionsToInputs.bind(this)
    this.mapEbayTransactions = this.mapEbayTransactions.bind(this)
    this.testInput = this.testInput.bind(this)
    this.processInput = this.processInput.bind(this)
    this.addTransaction = this.addTransaction.bind(this)
    this.processMultipleInputs = this.processMultipleInputs.bind(this)
    this.parseNumber = this.parseNumber.bind(this)
    this.getAccounts = this.getAccounts.bind(this)

  }

  transactionsToInputs(transactions) {
    return this.mapEbayTransactions(transactions)
      .then(this.mapInputs)
  }

  mapInputs(trans) {
    return Promise.resolve(trans.map(item => this.getInput(item)))
  }

  testInput(input) {
    if (!input.anchor.amount) return false
    if (!input.lineItems[0]) return false

    let lineItemsTotal = input.lineItems[0].amount
    const copy = input.lineItems.slice()
    copy.shift()
    copy.forEach(lineItem => {
      lineItemsTotal -= lineItem.amount
    })

    return this.parseNumber(lineItemsTotal) === Math.abs(this.parseNumber(input.anchor.amount));
  }

  processMultipleInputs(inputs) {
    return Promise.allSettled(inputs.map(input => {
      return this.processInput(input)
    }))
  }

  processInput(input) {
    if (!this.testInput(input)) {
      return Promise.reject(new Error(`Invalid input: ${JSON.stringify(input)}`))
    }

    return this.addTransaction(input)
      .then(response => response.json())
      .then(result => result.data)
      .then(data => data.moneyTransactionCreate)
      .then(transaction => {
        if (transaction.didSucceed) {
          return transaction.transaction
        } else {
          return 'transaction failed: ' + JSON.stringify(transaction.inputErrors)
        }
      })
  }

  parseNumber(num) {
    if (num === '--' || !num) return 0
    return +parseFloat(num).toFixed(2)
  }

  mapEbayTransactions(transactions) {
    return Promise.resolve(
      transactions.map(transaction => {
        const MULTIPLIER = transaction.bookingEntry === 'DEBIT' ? -1 : 1
        switch(transaction.transactionType) {
          /**
           * amount: {value: "0.25", currency: "USD"}
           * bookingEntry:"DEBIT"
           * feeType:"INSERTION_FEE"
           * payoutId:"6539731354"
           * references:[{referenceId: "226353543147", referenceType: "ITEM_ID"}]
           * salesRecordReference:"0"
           * transactionDate:"2024-09-15T18:01:11.952Z"
           * transactionId:"FEE-6271170175516_11"
           * transactionStatus:"PAYOUT"
           * transactionType:"NON_SALE_CHARGE"
           */
          case 'NON_SALE_CHARGE':
            return {
              type: transaction.transactionType,
              externalId: transaction.transactionId,
              date: transaction.transactionDate.split('T')[0],
              description: transaction.feeType + ' for ' + transaction.references.map(r=>r.referenceId).join(' '),
              salesAmount: 0,
              shippingAmount: 0,
              feeAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              grossAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              netAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              notes: JSON.stringify(transaction)
            }
          case 'SALE':
            return {
              type: transaction.transactionType,
              externalId: transaction.transactionId,
              date: transaction.transactionDate.split('T')[0],
              description: transaction.transactionType + ' to ' + transaction.buyer.username + ' Order ' + transaction.orderId,
              salesAmount: this.parseNumber((transaction.totalFeeBasisAmount.value || 0) * MULTIPLIER),
              shippingAmount: 0,
              feeAmount: this.parseNumber((transaction.totalFeeAmount.value || 0) * MULTIPLIER),
              grossAmount: this.parseNumber((transaction.totalFeeBasisAmount.value || 0) * MULTIPLIER),
              netAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              notes: JSON.stringify(transaction)
            }
          case 'REFUND':
            return {
              type: transaction.transactionType,
              externalId: transaction.transactionId,
              date: transaction.transactionDate.split('T')[0],
              description: transaction.transactionType + ' for ' + transaction.references.map(r=>r.referenceId).join(' '),
              salesAmount: this.parseNumber((transaction.totalFeeBasisAmount.value || 0) * MULTIPLIER),
              shippingAmount: 0,
              feeAmount: this.parseNumber((transaction.totalFeeAmount.value || 0) * MULTIPLIER),
              grossAmount: this.parseNumber((transaction.totalFeeBasisAmount.value || 0) * MULTIPLIER),
              netAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              notes: JSON.stringify(transaction)
            }
          default:
            return {
              type: transaction.transactionType,
              externalId: transaction.transactionId,
              date: transaction.transactionDate.split('T')[0],
              description: transaction.transactionMemo + ' for ' + (transaction.orderId || '--'),
              salesAmount: 0,
              shippingAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              feeAmount: 0,
              grossAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              netAmount: this.parseNumber((transaction.amount.value || 0) * MULTIPLIER),
              notes: JSON.stringify(transaction)
            }
        }

      })
    )
  }

  getInput({type, externalId, date, description, notes, netAmount, grossAmount, feeAmount}) {
    const input = {"businessId": this.config.WAVE_BUSINESS_ID || '',
      "externalId": externalId,
      "date": date,
      "description": description,
      "notes": notes,
      "anchor": {
        "accountId": this.config.WAVE_ANCHOR_ACCOUNT_ID || '',
        "amount": Math.abs(netAmount),
        "direction": netAmount < 0 ? "WITHDRAWAL" : "DEPOSIT"
      },
      "lineItems": []
    }
    switch(type) {
      case 'Order':
      case 'SALE':
      case 'REFUND':
        input.lineItems.push({
          "accountId": this.config.WAVE_SALES_ACCOUNT_ID || '',
          "amount": Math.abs(grossAmount),
          "balance": grossAmount < 0 ? "DECREASE" : "INCREASE"
        })
        input.lineItems.push({
          "accountId": this.config.WAVE_EBAY_FEES_ACCOUNT_ID || '',
          "amount": Math.abs(feeAmount),
          "balance": feeAmount > 0 ? "INCREASE" : "DECREASE"
        })
        break;
      case 'Other fee':
      case 'OTHER_FEE':
      case 'OTHER_EBAY_FEE':
      case 'NON_SALE_CHARGE':

        input.lineItems.push({
          "accountId": description.indexOf('AD_FEE') >= 0 ?
            this.config.WAVE_AD_FEES_ACCOUNT_ID || '' :
            this.config.WAVE_EBAY_FEES_ACCOUNT_ID || '',
          "amount": Math.abs(netAmount),
          "balance": netAmount < 0 ? "INCREASE" : "DECREASE"
        })
        break;
      case 'Shipping label':
      case 'SHIPPING_LABEL':
        input.lineItems.push({
          "accountId": this.config.WAVE_SHIPPING_ACCOUNT_ID || '',
          "amount": Math.abs(netAmount),
          "balance": netAmount < 0 ? "INCREASE" : "DECREASE"
        })
        break;
      case 'Payout':
      case 'PAYOUT':
        input.lineItems.push({
          "accountId": this.config.WAVE_CAPITAL_ONE_ID || '',
          "amount": Math.abs(netAmount),
          "balance": netAmount < 0 ? "INCREASE" : "DECREASE"
        })
        break;

    }

    return input
  }

  getBusinesses() {
    return fetch('https://gql.waveapps.com/graphql/public', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.config.WAVE_ACCESS_TOKEN}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `query {
  businesses(page: 1, pageSize: 10) {
    pageInfo {
      currentPage
      totalPages
      totalCount
    }
    edges {
      node {
        id
        name
        isPersonal
      }
    }
  }
}`})
    })
  }

  getAccounts() {
    return fetch('https://gql.waveapps.com/graphql/public', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.config.WAVE_ACCESS_TOKEN}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `query ($businessId: ID!, $page: Int!, $pageSize: Int!) {
  business(id: $businessId) {
    id
    accounts(page: $page, pageSize: $pageSize) {
      pageInfo {
        currentPage
        totalPages
        totalCount
      }
      edges {
        node {
          id
          name
          balance
          displayId
          type {
            name
            value
          }
          subtype {
            name
            value
          }          
        }
      }
    }
  }
}`,
        variables: {
          "businessId": this.config.WAVE_BUSINESS_ID,
          "page": 1,
          "pageSize": 50
        }
      })
    })
  }

  addTransaction(input) {
    return fetch('https://gql.waveapps.com/graphql/public', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${this.config.WAVE_ACCESS_TOKEN}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `mutation ($input:MoneyTransactionCreateInput!){
    moneyTransactionCreate(input:$input){
        didSucceed
        inputErrors{
            path
            message
            code
        }
        transaction{
            id
        }
    }
 }`,
        variables: {
          "input": input
        }
      })
    })
  }

  // async subscribeToNotification(token, topic) { // 'MARKETPLACE_ACCOUNT_DELETION'
  //   const subscriptionUrl = 'https://api.ebay.com/commerce/notification/v1/subscription';
  //
  //   const data = {
  //     topic: topic,
  //     status: 'ENABLED',
  //     payload: {
  //       deliveryProtocol: 'HTTPS',
  //       endpoint: 'https://app.eshopcommand.com/api/ebay/webhook',
  //       payloadVersion: '1.0',
  //       authorization: {
  //         type: 'OAUTH2',
  //         token: token,
  //       },
  //     }
  //   };
  //
  //   const response = await axios.post(subscriptionUrl, data, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`
  //     }
  //   });
  //
  //   return response.data;
  // }


}


// module.exports = {
//   transactionsToInputs,
//   processMultipleInputs,
//   addTransaction,
//   getTransactions,
// }
