<template>
  <div>
  <transition mode="out-in">
    <form v-if="showForm" class="d-flex" @submit.prevent="createStore">
      <div class="input-group">
        <input type="text" class="form-control" placeholder="Store Name" v-model="storeName">
        <button type="submit" class="btn btn-primary">Create Store</button>
        <button type="button" class="btn btn-light" @click="toggleForm">
          Cancel
        </button>
      </div>
    </form>
    <div v-else class="d-flex">
      <button class="ms-auto btn btn-light" @click="toggleForm">
        &plus; Add Store
      </button>
    </div>
  </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import {useStore} from "vuex";
//import {useRouter} from "vue-router";
const store = useStore()
//const router = useRouter()
import {useAuth0} from "@auth0/auth0-vue";
const auth0 = useAuth0();

let showForm = ref(false)
let storeName = ref('')

function toggleForm() {
  storeName.value = ''
  showForm.value = !showForm.value
}

function createStore() {
  if (storeName.value.toString().trim().length <= 0) {
    alert('Name required')
  }

  store.dispatch('eshop/createStore', {auth0, name: storeName.value})
      .then(() => toggleForm())
      .catch(err => console.log(err))

}




</script>

<style lang="scss" scoped>

</style>
