<template>
  <button class="btn btn-primary px-5 btn-lg" @click="login">Sign in</button>
</template>
<script setup>
import { useAuth0 } from '@auth0/auth0-vue';

const { loginWithRedirect } = useAuth0();

function login () {
  loginWithRedirect();
}
</script>
