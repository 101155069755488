import { createAuth0 } from '@auth0/auth0-vue';

export default createAuth0({
  domain: "dev-y2bjwa0amwrmxqy8.us.auth0.com",
  clientId: "JtkIZSYNAnCf8EMofF5OD91oMjAsD3EF",
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: "https://api.eshopcommand.com"
  }
})
