<template>
  <div v-if="isManager">
    <store-nav />
    <div id="main-content" class="container container-container">
      <div id="main" class="bg-white border-start border-end p-4 pb-5">
        <router-view v-if="store.state.eshop.store" />
      </div>
    </div>
  </div>
</template>

<script setup>
import StoreNav from "@/components/StoreNav.vue";
import { defineProps, computed } from 'vue'
import { useAuth0 } from "@auth0/auth0-vue";
import { useStore } from 'vuex'
import { useRouter } from "vue-router";

const store = useStore()
const auth0 = useAuth0();
const router = useRouter()
const props = defineProps({
  storeKey: {
    type: String,
    required: true
  }
})
const isManager = computed(() => store.getters.hasRole('manager.eshop'))

if (!store.state.eshop.store || store.state.eshop.store.id !== props.storeKey) {
  store.dispatch('eshop/loadStores', auth0)
    .then(() => {
      const currentStore = store.state.eshop.stores.slice().filter(s => s.id == props.storeKey)
      if (!currentStore[0]) {
        router.replace({name: 'Home'})
      }
      store.dispatch('eshop/selectStore', {auth0, store: currentStore[0]})
    })
}

</script>
