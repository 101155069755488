<template>
  <div v-if="isAdmin">
    <div id="main-content" class="container container-container">
      <div id="main" class="bg-white border-start border-end p-4 pb-5">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const isAdmin = computed(() => store.getters.hasRole('admin.owner'))
</script>
