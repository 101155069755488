import axios from 'axios'
let apiRoot
if (process.env.NODE_ENV === 'production') {
  // Production-specific code
  apiRoot = '/api' // when served through express
} else {
  // Development-specific code
  apiRoot = 'http://localhost:3000/api' // when separate api server
}



const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

//let isAlreadyFetchingAccessToken = false
// let subscribers = []
//
// function onAccessTokenFetched(access_token) {
//   subscribers.forEach(callback => callback(access_token))
//   subscribers = []
// }
//
// function addSubscriber(callback) {
//   subscribers.push(callback)
// }

let ApiInstance

export default (token) => {
  if (!ApiInstance) {
    ApiInstance = axios.create({
      baseURL: apiRoot,
      timeout: 30000,
      withCredentials: false,
      headers: headers
    });
  }

  if (token) {
    ApiInstance.defaults.headers['authorization'] = `Bearer ${token}`
  }

  ApiInstance.interceptors.response.use(response => {
    return response
  }, error => {

    const {response: {status, data: {message}}} = error

    // const originalRequest = config

    if (status === 401) {
      return Promise.reject(new Error(message || 'Login Failed'))
    }

    return Promise.reject(error)

  })

  return ApiInstance;

}
