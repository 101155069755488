<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light-subtle-subtle border-dark border-bottom shadow">
    <div class="container">
      <a href="/" :title="store.state.appName" class="me-3 navbar-brand d-flex align-items-center">
        <img src="@/assets/esc.svg" class="nav-logo me-2" alt="eShopCommand">
      </a>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>


      <div class="collapse navbar-collapse" id="navbarNav">
<!--        <div class="navbar-nav" v-if="isAuthenticated">-->
<!--&lt;!&ndash;          <a v-if="isManager" href="#" @click.prevent="logoutClient" class="nav-link">My eShops</a>&ndash;&gt;-->
<!--          <router-link v-if="isAdmin" class="nav-link" :to="{name:'Admin'}">Admin</router-link>-->
<!--        </div>-->
        <div v-if="isAuthenticated" class="navbar-nav ms-auto align-items-center">
          <router-link v-if="isAdmin" class="nav-link me-2" :to="{name:'Admin'}">Admin</router-link>
          <div class="ms-auto d-inline-flex align-items-center">

          <router-link v-if="isStore" :to="{name:'Dashboard', params:{storeKey}}" class="nav-link">
            {{ storeName }}
          </router-link>
          <router-link class="nav-link mx-3" :to="{name:'Profile'}">
            <img :src="user.picture" style="height:60px;width:60px" class="rounded-circle" :title="user.email" :alt="user.email">
          </router-link>
            </div>
        </div>

      </div>


    </div>
  </nav>
</template>

<script setup>

import { computed } from 'vue'
import { useStore } from 'vuex'
//import { useRouter } from 'vue-router'
import {useAuth0} from "@auth0/auth0-vue";

const { user, isAuthenticated } = useAuth0();

const store = useStore()
//const router = useRouter()

const isStore = computed(() => !!store.state.eshop.store)
const storeName = computed(() => isStore.value && store.state.eshop.store.name || '')
//const storeKey = computed(() => isStore.value && store.state.eshop.store.id || '')
const isAdmin = computed(() => store.getters.hasRole('admin.owner'))
//const isManager = computed(() => store.getters.hasRole('manager.eshop'))

// function logoutClient() {
//   store.commit('setStore', null)
//   router.replace({ name: 'Home' })
// }

</script>

<style scoped>
.nav-logo {
  height:80px;
}
</style>
