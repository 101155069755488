import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import DefaultContainer from "@/containers/DefaultContainer.vue"
import StoreContainer from "@/containers/StoreContainer.vue"
import AdminContainer from "@/containers/AdminContainer.vue"
// import store from '@/store'

//import { authGuard } from "@auth0/auth0-vue";

const DEFAULT_TITLE = 'eShopCommand'

const routes = [
  {
    path: '/',
    redirect: '/stores',
    name: 'Home',
    component: DefaultContainer,
    children: [
      {
        path: 'stores',
        name: 'Stores',
        component: Home,
        meta: { title: 'My Stores'}
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "timecards" */ '../views/Profile.vue'),
        //beforeEnter: authGuard,
        meta: { title: 'My Profile' }
      },
      {
        path: 'ebay/callback',
        name: 'EbayCallback',
        component: () => import( '../views/EbayCallback.vue'),
        props: true,
      }
    ]
  },

  {
    path: '/store/:storeKey?',
    name: 'Store',
    component: StoreContainer,
    beforeEnter: requireStore,
    props: true,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "store" */ '../views/store/Dashboard.vue'),
        meta: { title: 'Dashboard' }
      },
      {
        path: 'inventory',
        name: 'Inventory',
        component: () => import(/* webpackChunkName: "store" */ '../views/store/Inventory.vue'),
        meta: { title: 'Inventory' }
      },
      {
        path: 'expenses',
        name: 'Expenses',
        component: () => import(/* webpackChunkName: "store" */ '../views/store/Expenses.vue'),
        meta: { title: 'Expenses' }
      },
      {
        path: 'accounting',
        name: 'Accounting',
        component: () => import(/* webpackChunkName: "store" */ '../views/store/Accounting.vue'),
        meta: { title: 'Accounting' }
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "store" */ '../views/store/Settings.vue'),
        meta: { title: 'Settings' }
      },
      {
        path: 'delete',
        name: 'DeleteStore',
        component: () => import( '../views/store/DeleteStore.vue'),
        meta: { title: 'Remove Store' }
      }
    ]
  },

  {
    path: '/admin',
    name: 'Admin',
    redirect: '/admin/config-keys',
    component: AdminContainer,
    children: [
      {
        path: 'config-keys',
        name: 'ConfigKeys',
        component: () => import( '@/views/admin/ConfigKeys.vue'),
        meta: { title: 'Admin - Config Keys'}
      },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  //history: createWebHistory(process.env.BASE_URL),
  routes
})

function requireStore(to, from, next) {
  return next()
}

router.beforeEach((to, from, next) => {
//   return next()
//   // if (to.name !== 'Home' && !auth0.isAuthenticated) {
//   //   store.nextRoute = to
//   //   return next({ name: 'Home' })
//   // }
  const titleArray = []
  if (to.meta && to.meta.title) {
    titleArray.push(to.meta.title)
  }
  titleArray.push(DEFAULT_TITLE)
  document.title = titleArray.join(' | ')
  next()
})

export default router
