import api from "@/store/api";
import ebay from "@/services/ebay";

export default {
  namespaced: true,
  state: {
    store: null,
    stores: null,
    globalConfigs: [
      'WAVE_BUSINESS_ID',
      'WAVE_ANCHOR_ACCOUNT_ID',
      'WAVE_SHIPPING_ACCOUNT_ID',
      'WAVE_SALES_ACCOUNT_ID',
      'WAVE_EBAY_FEES_ACCOUNT_ID',
      'WAVE_AD_FEES_ACCOUNT_ID',
    ],
    ebayCode: null,
    ebayUserToken: null,
  },
  getters: {
    config: state => key => state.store.config[key] || null,
  },
  actions: {
    async loadStores({ commit }, auth0) {
      const token = await auth0.getAccessTokenSilently()
      return api(token).get('/stores')
        .then(response => response.data)
        .then(stores => {
          commit('setStores', stores || [])
          return stores || []
        })
    },
    async selectStore({ commit }, {auth0, store}) {
      const token = await auth0.getAccessTokenSilently()
      return api(token).get('/store/get-config', {
        params: {
          storeKey: store.id
        }
      })
        .then(response => response.data)
        .then(data => data.config)
        .then(conf => {
          commit('setStore', {...store, config: conf})
        })
    },
    async createStore({ commit }, {auth0, name}) {
      const token = await auth0.getAccessTokenSilently()
      return api(token).post('/stores/store', {
        name: name
      }).then(response => response.data)
        .then(data => data.store)
        .then(store => commit('addStore', store))

    },
    async saveStoreConfig({ state, commit }, {auth0, key, val}) {
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      const token = await auth0.getAccessTokenSilently()
      return api(token).post('/store/config', {
        storeKey: state.store.id,
        key: key,
        val: val,
      })
        .then(response => response.data)
        .then(data => data.conf)
        .then(conf => {
          const configuration = state.store.config
          configuration[conf.data_key] = conf.data_value
          commit('setStore', {...state.store, config: configuration})
        })
      // .then(store => {
      //   commit('setStore', store)
      //   commit('eshop/updateStores', store)
      // })
    },
    async checkTransaction( { state }, {auth0, key} ) {
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      const token = await auth0.getAccessTokenSilently()
      return api(token).get('transactions/check', {
        params: {
          transactionId: key,
          storeId: state.store.id
        }
      })
        .then(response => response.data)
        .then(data => data.imported)

    },
    async getEbayData({ state, commit }, {auth0, type, dates}){
      if (!auth0.isAuthenticated || !state.store.config.EBAY_USER_ACCESS_TOKEN) {
        return Promise.resolve('not authenticated')
      }

      const ebayService = new ebay(state.store.config)

      const token = await auth0.getAccessTokenSilently()
      return api(token).post('ebay/get-data', {
        accessToken: state.store.config.EBAY_USER_ACCESS_TOKEN,
        type: type,
        dates: dates
      })
        .then(response => response.data)
        .then(data => data.transactions)
        .then(transactions => ebayService.transactionsToInputs(transactions))
        .then(inputs => {
          console.log(inputs)
          //const storeInputs = {...state.store.inputs, [type]: inputs}
          commit('setStore', {...state.store, inputs: inputs})
          return inputs
        })
    },
    async mapTransaction({ state }, {auth0, transactionId}) {
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      const token = await auth0.getAccessTokenSilently()
      return api(token).get('transactions/map', {
        params: {
          transactionId: transactionId,
          storeId: state.store.id
        }
      })
        .then(response => response.data)
        .then(data => data.imported)
    },
    addWaveInput({state}, input) {
      const ebayService = new ebay(state.store.config)
      return ebayService.addTransaction(input)
        .then(response => response.json())
    },
    getWaveBusinesses({state}) {
      const ebayService = new ebay(state.store.config)
      return ebayService.getBusinesses()
        .then(response => response.json())
        .then(body => {
          if (body.errors) {
            throw new Error (body.errors[0].message)
          }
          return body.data
        })
        .then(data => data.businesses)
        .then(businesses => businesses.edges || [])
        .then(b => b.map(bus => bus.node))
    },
    getWaveAccounts({state, commit}, page=0) {
      if (state.store.accounts) return Promise.resolve(state.store.accounts)
      const ebayService = new ebay(state.store.config)
      return ebayService.getAccounts(page)
        .then(response => response.json())
        .then(body => {
          if (body.errors) {
            throw new Error (body.errors[0].message)
          }
          return body.data
        })
        .then(data => data.business)
        .then(business => business.accounts)
        .then(a => a.edges)
        .then(edges => edges.map(e => e.node))
        .then(accounts => {
          commit('setStore', {...state.store, accounts})
          return accounts
        })
    },
    async getEbayAuthUrl({state}, auth0) {
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      const token = await auth0.getAccessTokenSilently()
      return api(token)
        .get('/ebay/get-auth-url', {
          params: {
            state: state.store.id
          }})
        .then(response => response.data)
        .then(data => data.authUrl)
    },
    async getEbayUserToken({commit}, {auth0, code}) {
      if (!auth0.isAuthenticated) {
        return Promise.resolve('not authenticated')
      }
      const token = await auth0.getAccessTokenSilently()
      return api(token)
        .get('/ebay/callback', {params:{code}})
        .then(response => response.data)
        .then(data => {
          if (data.access_code) {
            commit('setEbayAccessToken', data)
          } else {
            return Promise.reject(data)
          }

          return Promise.resolve(true)
        })
    },
  },
  mutations: {
    setEbayCode(state, code) {
      state.ebayCode = code
    },
    setEbayAccessToken(state, accessToken) {
      state.ebayUserToken = accessToken
    },
    setStores: (state, stores) => {
      state.stores = [...stores]
    },
    addStore: (state, store) => {
      state.stores.push(store)
    },
    setStore: (state, payload) => {
      if (!payload) {
        state.store = null
        return
      }
      state.store = payload //{...payload, config: JSON.parse(payload.config || "{}")}
    },
    updateStores: (state, payload) => {
      state.stores = state.stores.map(store => {
        if (store.id === payload.id) {
          return payload
        }
        return store
      })
    }
  }
}
