<template>
  <div>
    <div class="list-group">
      <div
          type="button"
          class="list-group-item list-group-item-action"
          @click="selectStore(store)"
          v-for="store in stores"
          :key="store.id"
      >{{ store.name }}</div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {useAuth0} from '@auth0/auth0-vue'

const store = useStore()
const router = useRouter()
const auth0 = useAuth0()
const stores = computed(() => store.state.eshop.stores || [])

function selectStore(newStore) {
  store.dispatch('eshop/selectStore', {auth0, store:newStore})
      .then(() => router.push({name: 'Dashboard', params: {storeKey: newStore.id}}))
      .catch(e => {
        alert(e.message)
      })

}

</script>

<style lang="scss" scoped>

</style>
